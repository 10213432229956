<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <b-link class="d-none d-lg-flex brand-logo">
                <img :src="imgLogo" alt="" height="80">
            </b-link>

            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img fluid :src="imgUrl" alt="register V2" />
                </div>
            </b-col>

            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <div class="d-flex d-lg-none align-items-center pb-3">
                        <img :src="imgLogo" alt="" class="w-100" style="max-width: 100%;">
                    </div>

                    <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
                        Solicitar acceso
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Rellena el formulario para solicitar acceso a la plataforma GMAO
                    </b-card-text>

                    <validation-observer ref="registerForm" #default="{ invalid }">
                        <b-form class="auth-register-form mt-2" @submit.prevent="register">
                            <b-form-group label="Compañia" label-for="register-company">
                                <validation-provider name="company" vid="company" rules="required">
                                    <b-form-input id="register-company" v-model="userRegisterRequest.company"
                                        name="register-company" placeholder="Compañia" />
                                </validation-provider>
                                <small v-if="validation.company" class="text-danger w-100">
                                    {{ validation.company }}
                                </small>
                            </b-form-group>
                            <b-form-group label="Nombre" label-for="register-email">
                                <validation-provider name="name" vid="name" rules="required">
                                    <b-form-input id="register-name" v-model="userRegisterRequest.name" name="register-name"
                                        placeholder="Nombre" />
                                </validation-provider>
                                <small v-if="validation.name" class="text-danger w-100">
                                    {{ validation.name }}
                                </small>
                            </b-form-group>
                            <b-form-group label="Trabajo" label-for="register-email">
                                <validation-provider name="job" vid="job" rules="required">
                                    <b-form-input id="register-job" v-model="userRegisterRequest.job" name="register-job"
                                        placeholder="Trabajo" />
                                </validation-provider>
                                <small v-if="validation.job" class="text-danger w-100">
                                    {{ validation.job }}
                                </small>
                            </b-form-group>
                            <b-form-group label="Email" label-for="register-phone">
                                <validation-provider name="email" vid="email" rules="required|email">
                                    <b-form-input id="register-email" v-model="userRegisterRequest.email"
                                        name="register-email" placeholder="Email" />
                                </validation-provider>
                                <small v-if="validation.email" class="text-danger w-100">
                                    {{ validation.email }}
                                </small>
                            </b-form-group>
                            <b-form-group label="Teléfono" label-for="register-phone">
                                <validation-provider name="phone" vid="phone" rules="required">
                                    <b-form-input id="register-phone" v-model="userRegisterRequest.phone"
                                        name="register-phone" placeholder="Teléfono" />
                                </validation-provider>
                                <small v-if="validation.phone" class="text-danger w-100">
                                    {{ validation.phone }}
                                </small>
                            </b-form-group>

                            <b-button type="submit" variant="primary" block :disabled="loading">
                                <b-spinner v-if="loading" label="Cargando..." />
                                <span v-else>Solicitar</span>
                            </b-button>

                            <router-link :to="{ name: 'auth-register' }" class="mt-1 d-block">
                                <b-button type="button" variant="secondary" block>
                                    <span>Iniciar sesión</span>
                                </b-button>
                            </router-link>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BSpinner, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText,
    BCardTitle, BImg, BForm, BButton, VBTooltip
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import Vue from "vue"
import { mapState } from "vuex"
export default {
    name: 'createUserRequestForm',
    directives: {
        'b-tooltip': VBTooltip,
    },
    components: {
        BSpinner,
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            loading: false,
            invalid: false,
            status: '',
            userRegisterRequest: {
                company: null,
                name: null,
                job: null,
                email: null,
                phone: null,
            },
            sideImg: require('@/assets/images/pages/userRequest.png'),

            // validation rules
            required,
            email,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/userRequest.png')
                return this.sideImg
            }
            return this.sideImg
        },
        imgLogo() {
            return require('@/assets/images/logo/logo_steknos.png')
        },
        ...mapState('userRegisterRequest', ['validation']),
    },
    methods: {
        register() {

            console.log(this.userRegisterRequest);
            this.loading = true

            this.$store.dispatch('userRegisterRequest/create', this.userRegisterRequest).then(response => {
                this.loading = false
                this.$router.replace('/login')
                    .then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Enviada`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                                text: `Solicitud de registro enviada correctamente.`,
                            },
                        })
                    })
            }).catch(error => {
                console.log(error)
                Vue.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: error.title,
                        icon: 'CoffeeIcon',
                        variant: 'danger',
                    },
                })
                this.loading = false
                this.$refs.registerForm.setErrors(error.message)
            })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
